.only-desktop {
  @include screen('<=tablet') {
    display: none !important;
  }
}

.only-desktop-xl {
  @include screen('<=desktop') {
    display: none !important;
  }
}

.only-desktop-xxl {
  @include screen('<=desktop-xl') {
    display: none !important;
  }
}

.non-desktop-xxl {
  display: none !important;
  @include screen('<=desktop-xl') {
    display: unset;
  }
}

.only-phone {
  display: none !important;
  @include screen('<=phone') {
    display: block !important;
  }
}

.non-phone {
  @include screen('<=phone') {
    display: none !important;
  }
}

.only-phone-small {
  display: none !important;
  @include screen('<=phone-small') {
    display: block !important;
  }
}

.non-phone-small {
  @include screen('<=phone-small') {
    display: none !important;
  }
}

.non-tablet {
  @include screen('<=tablet') {
    display: none !important;
  }
}

.only-tablet {
  display: none !important;
  @include screen('<=tablet') {
    display: block !important;
  }
}

.inline {
  display: inline;
}

.hover-able {
  opacity: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

td {
  word-break: break-word;
  border-top: none !important;
  border-bottom: none !important;
}

.cdk-overlay-pane {
  min-width: 35em;

  @include screen('<=phone') {
    width: 100%;
    min-width: unset;
    max-width: unset !important;
  }

  .mdc-dialog__surface {
    background: var(--background-light);
    border-radius: var(--border-radius);
    padding: 20px;

    .mat-mdc-dialog-title {
      &::before {
        content: none;
      }

      display: flex;
      justify-content: space-between;
      padding: 0;
    }
  }
}

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  overflow: auto;

  .mat-button-toggle {
    flex-grow: 1;
  }

  .mat-button-toggle-checked {
    background-color: var(--color-secondary-tint-4);
  }
}

.mat-mdc-form-field {
  margin: 10px 0;
  height: fit-content;
}

.mat-mdc-row {
  &:hover {
    background-color: var(--color-secondary-tint-6) !important;
    cursor: pointer;

    @include screen('<=phone') {
      background-color: unset !important;
      cursor: none;
    }

    @include screen('<=tablet') {
      background-color: unset;
      cursor: unset;
    }
  }
}

.center {
  width: 100%;
  text-align: center;
}

.mat-step-icon span {
  color: var(--white-19);
}

.mat-step-icon {
  background-color: var(--white-13) !important;

  &.mat-step-icon-selected {
    background-color: var(--white-11) !important;
  }
}

button.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0; // -> Padding for icon-buttons is useless
}

.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
}

div.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.mat-mdc-table {
  background: unset;
  width: 100%;

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    padding: 5px !important;

    @include screen('<=phone') {
      padding: 10px 4px !important;
    }
  }

  .mdc-data-table__row {
    height: 40px !important;
  }
}

.mat-mdc-outlined-button {
  line-height: 45px !important;
}

.mat-mdc-form-field-bottom-align {
  white-space: nowrap;
}

.snackbar span {
  color: var(--font-light);
  font-size: 16px;
}

.width-100 {
  width: 100%;
}

.links-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  text-align: center;

  @include screen('<=phone') {
    flex-direction: column;
    gap: 10px;
  }
}

.card {
  position: relative;
  height: fit-content;
  background: var(--background-light);
  border-radius: var(--border-radius);
  width: var(--default-content-width);
  max-width: var(--max-content-width);
  margin: 20px 0;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 50px;
  box-sizing: border-box;

  @include screen('<=tablet') {
    padding: 30px 20px;
  }
  @include screen('<=phone') {
    padding: 15px 15px;
  }
}

hr {
  display: block;
  height: 4px;
  border: 0;
  border-top: 2px solid var(--hr);
  margin: 20px 0;
  padding: 0;
  width: 150px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.mdc-button {
  min-width: 130px !important;
  height: var(--button-height) !important;
  white-space: nowrap;
  border: 1px solid var(--color-secondary-shade-4) !important;

  span {
    font-size: 16px;
    color: var(--color-secondary-shade-3);
    font-family: 'font-primary-bold', sans-serif;
  }

  &.primary,
  &[type='submit'] {
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary-shade-1) !important;

    span {
      color: var(--font-light);
    }
  }

  &.mat-mdc-button {
    margin: 10px 0;
  }

  &:disabled {
    opacity: 0.7;
  }

  &.no-border-radius {
    border-radius: 0 !important;
  }
}

.mat-mdc-dialog-content {
  max-height: 82vh !important;
  margin: 0 !important;
  padding: 0 15px !important;
  overflow: unset !important;
  height: 100% !important;

  @include screen('<=phone') {
    padding: 0 5px !important;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    textarea {
      width: 100%;
    }
  }
}

.break-word {
  word-break: break-word;
}

textarea.mat-mdc-input-element {
  height: 150px;
  line-height: 26px !important;
}

mat-expansion-panel.mat-expansion-panel {
  width: 100%;
  margin: 3px 0;
}

.mdc-checkbox__checkmark {
  color: white !important;
}

.page-content-public {
  flex-direction: column;

  .floating-infos-phone {
    display: none;
    @include screen('<=phone') {
      display: unset;
    }
  }

  .page-part {
    height: calc(var(--real-100-vh) - var(--small-header-height));
    flex-wrap: wrap;

    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .flex-wrapper {
      display: flex;
      width: var(--max-content-width);
      height: var(--max-content-width);
      gap: 50px;
      align-items: center;
      text-align: end;
      word-break: break-word;

      @include screen('<=tablet') {
        flex-direction: column !important;
        text-align: unset !important;
        justify-content: space-evenly;
      }

      .page-header-image {
        object-fit: cover;
        height: 100%;
        width: 60%;

        @include screen('<=tablet') {
          width: 100% !important;
        }

        &.map {
          width: 100%;
        }
      }

      // This is the first page-part
      &.landing-part {
        flex-direction: column !important;
        width: 100%;
        gap: unset;
        text-align: center !important;
        position: absolute;
        top: var(--header-height);
        height: -webkit-fill-available;

        @include screen('<=phone') {
          top: var(--small-header-height);
          height: calc(100% - var(--small-header-height));
          justify-content: unset;
        }

        .page-header-image {
          width: 100%;
          height: 60%;

          @include screen('<=desktop') {
            min-height: 50%;
          }
          @include screen('<=phone') {
            min-height: 0;
            height: 70%;
          }

          .swiper {
            height: 100%;
          }

          img {
            position: relative !important;
            object-fit: cover;
          }
        }

        .page-intro {
          height: 40%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .header-text {
            display: flex;
            width: 70%;
            height: 100%;
            justify-content: space-evenly;
            flex-direction: column;
          }

          .arrow-icon {
            margin-bottom: 15px;

            @include screen('<=phone') {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    &:nth-child(even) {
      background-color: var(--color-secondary-tint-4);
    }

    &:nth-child(even) .flex-wrapper {
      flex-direction: row-reverse;
      text-align: start;
    }
  }
}

.no-margin {
  margin: 0;
}

.img-background {
  background: url('/assets/icons/image-gray.svg') no-repeat center center;
  background-size: contain;
}
