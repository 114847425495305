@import 'src/styles/cky_audit_table';
@import 'src/styles/project_theme';
@import 'src/styles/material';
@import 'src/styles/text';
@import 'src/styles/fonts';
@import 'src/styles/global';
@import 'src/styles/variables';
@import 'src/styles/mixins.scss';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  background: var(--color-secondary-tint-6);
  font-family: 'font-primary', sans-serif;
  overflow-wrap: break-word;
}
