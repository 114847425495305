@font-face {
  font-family: 'font-primary-bold';
  src: url(/assets/schuetzenaigen/fonts/Comfortaa-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'font-primary';
  src: url(/assets/schuetzenaigen/fonts/Comfortaa-Regular.ttf)
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'font-primary-light';
  src: url(/assets/schuetzenaigen/fonts/Comfortaa-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'font-title';
  src: url(/assets/schuetzenaigen/fonts/LibreBaskerville-Regular.ttf)
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'font-title-bold';
  src: url(/assets/schuetzenaigen/fonts/LibreBaskerville-Bold.ttf)
    format('truetype');
  font-display: swap;
}
