@import 'src/styles/mixins';

.mat-form-field-label,
.mat-form-field-outline-gap,
.mat-form-field-outline-start,
.mat-form-field-outline-end,
.mat-form-field-outline-thick,
.mat-form-field-outline-thin,
.mat-form-field-ripple,
.mat-input-element,
.mat-select-placeholder,
.mat-option-text,
mat-label,
textarea,
input {
  font-size: 20px !important;
  line-height: 1.3 !important;
  @include screen('<=tablet') {
    font-size: 16px !important;
  }
}

h1,
h2,
h3,
h4,
p,
u,
b,
a,
li,
span {
  color: var(--font-dark);
  font-size: 20px;
  line-height: 1.3;
  word-break: break-word;
  @include screen('<=tablet') {
    font-size: 16px;
  }
}

a {
  color: var(--color-secondary-shade-5);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--color-secondary);
  }
}

p {
  &.comfort-read {
    text-align: justify;
    line-height: 1.8;
  }
}

td,
th {
  color: var(--font-dark) !important;
  font-size: 20px !important;
  @include screen('<=tablet') {
    font-size: 16px !important;
  }
  @include screen('<=phone') {
    font-size: 14px !important;
  }
}

h1 {
  font-family: 'font-primary-light', sans-serif;
  font-size: 48px;
  @include screen('<=tablet') {
    font-size: 30px;
  }
  @include screen('<=phone') {
    font-size: 20px;
  }
}

h2 {
  font-size: 24px;
  @include screen('<=tablet') {
    font-size: 22px;
  }
  @include screen('<=phone') {
    font-size: 18px;
  }
}

h3 {
  font-size: 22px;
  @include screen('<=tablet') {
    font-size: 18px;
  }
  @include screen('<=phone') {
    font-size: 16px;
  }
}

th {
  font-family: 'font-primary-bold', sans-serif;
  color: var(--font-dark) !important;
  white-space: nowrap;
}

.font-color-dark {
  color: var(--font-dark);
}

.font-color-color {
  color: var(--color-primary-shade-1);
}

a.font-color-dark {
  color: var(--color-secondary-shade-5);
  text-decoration: underline;

  &:hover {
    color: var(--color-secondary-shade-7);
  }
}

.font-color-light {
  color: var(--font-light);
}

.font-light {
  font-family: 'font-primary-light', sans-serif;
}

.font-bold {
  font-family: 'font-primary-bold', sans-serif;
}

.font-highlight {
  color: var(--color-primary-shade-4);
}

.font-gigantic {
  font-family: 'font-primary-light', sans-serif;
  font-size: 58px;
  word-break: inherit;
  @include screen('<=tablet') {
    font-size: 42px;
  }
  @include screen('<=phone') {
    font-size: 20px;
  }
}

.font-page-intro-title {
  font-family: 'font-title', sans-serif;
  font-weight: unset;
  color: var(--font-light);
  word-break: inherit;
}

.font-big {
  font-size: 22px;

  @include screen('<=tablet') {
    font-size: 20px;
  }
  @include screen('<=phone') {
    font-size: 18px;
  }
}

.font-small {
  font-size: 16px;
  @include screen('<=tablet') {
    font-size: 14px;
  }
}

.font-micro {
  font-size: 12px;
}

.font-admin {
  color: var(--font-admin);
}

.font-underline {
  text-decoration: underline;
}
