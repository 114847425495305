@import 'src/styles/mixins';

mat-form-field {
  &.mat-mdc-form-field {
    width: 100%;
    max-width: 400px;
  }

  .mat-mdc-form-field-infix {
    width: unset;
  }
}

button.mat-mdc-icon-button.mat-mdc-button-base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
}
