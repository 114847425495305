:root {
  // Colors
  // Base color
  --1: #e4eddf;
  // Shades
  --2: #c8cfc3;
  --3: #abb2a7;
  --4: #8e948b;
  --5: #72766f;
  --6: #555954;
  --7: #393b38;
  --8: #1c1e1c;
  // Tints
  --9: #e7efe3;
  --10: #ebf2e7;
  --11: #eef4eb;
  --12: #f1f6ef;
  --13: #f5f8f3;
  --14: #f8faf7;
  --15: #fcfdfb;
  // Complementary
  --16: #e8dfed;
  // Tetradic
  --17: #dfebed;
  --18: #e8dfed;
  --19: #ede1df;
}
