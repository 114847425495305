@import 'src/styles/mixins';

.cky-cookie-audit-table th,
.cky-cookie-audit-table td {
  background-color: var(--color-secondary-tint-7) !important;
  border: 1px solid var(--color-secondary-shade-6) !important;

  @include screen('<=phone') {
    padding: 5px !important;
    font-size: 12px !important;
  }
}
