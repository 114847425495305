// This file gets merged with the corresponding theme/variables.scss (e.g. tmkaigen/variables.scss)

:root {
  // Sizes
  --real-100-vh: 100vh; // -> This is calculated in app.component. Necessary for mobile with browser-headers
  --initial-100-vh-found: 100vh; // -> This is calculated in app.component. Necessary for mobile with browser-headers
  --min-landing-page-content-height: calc(
    var(--real-100-vh) - var(--header-height)
  );
  --default-content-width: 1500px;
  --default-text-width: 850px;
  --max-content-width: 80%;
  --header-height: 85px;
  --footer-height: 200px;
  --small-header-height: 60px;
  --button-height: 45px;
  --border-radius: 6px;
  --small-card: 600px;

  @include screen('<=desktop-xl') {
  }

  @include screen('<=desktop') {
    --max-content-width: 90%;
  }

  @include screen('<=tablet') {
  }

  @include screen('<=phone') {
    --header-height: var(--small-header-height);
    --footer-height: 300px;
  }

  // Whites
  // light
  --white-1: #ffffff;
  --white-2: #fbfbfb;
  --white-3: #f9f9f9;
  --white-4: #f6f6f6;
  --white-5: #f4f4f4;
  --white-6: #f2f2f2;
  --white-7: #f0f0f0;
  --white-8: #eeeeee;
  // dark
  --white-9: #e4e4e4;
  --white-10: #d2d2d2;
  --white-11: #c5c5c5;
  --white-12: #b7b7b7;
  --white-13: #a9a9a9;
  --white-14: #9b9b9b;
  --white-15: #8d8d8d;
  // really dark
  --white-16: #727272;
  --white-17: #565656;
  --white-18: #393939;
  --white-19: #1d1d1d;

  // Colors (source https://htmlcolorcodes.com/color-picker/)
  // Base colors
  --color-primary: #9fc1a5;
  --color-primary-tint-2: #b7d1bb;
  --color-primary-tint-4: #cfe0d2;
  --color-primary-shade-1: #8ba990;
  --color-primary-shade-3: #637967;
  --color-primary-shade-3-tone-2: #6a7b6d;
  --color-primary-shade-4: #506153;
  --color-primary-shade-5: #3c483e;

  --color-secondary: #c1b69f;
  --color-secondary-tint-4: #e0dbcf;
  --color-secondary-tint-6: #f0ede7;
  --color-secondary-tint-7: #f7f6f3;
  --color-secondary-shade-2: #a99f8b;
  --color-secondary-shade-2-tone-3: #999387;
  --color-secondary-shade-3: #797263;
  --color-secondary-shade-3-tone-2: #7b756a;
  --color-secondary-shade-3-tone-4: #7b776e;
  --color-secondary-shade-4: #615b50;
  --color-secondary-shade-4-tone-3: #6c6962;
  --color-secondary-shade-5: #48443c;
  --color-secondary-shade-6: #302d28;
  --color-secondary-shade-7: #181714;

  // Assign them!
  --home-title-header: var(--color-secondary-tint-6);

  --background-primary: var(--color-secondary-tint-4);
  --background-secondary: var(--color-secondary);
  --background-dark: var(--white-11);
  --background-light: var(--white-1);

  --header-background: var(--color-secondary-tint-4);

  --footer-background: var(--color-secondary-shade-4);
  --footer-font: var(--white-19);

  --font-dark: var(--white-19);
  --font-light: white;
  --font-admin: var(--white-19);

  --icon-dark: var(--white-17);
  --icon-light: var(--15);

  --hr: var(--white-8);
  --button: var(--19);
  --dropzone: var(--15);
  --progress-bar-after: var(--14);
  --progress-bar-before: var(--4);
  --input-background: var(--15);
}
