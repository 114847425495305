$breakpoints: (
  '<=phone-small': 360px,
  '<=phone': 600px,
  '<=tablet': 960px,
  '<=desktop': 1280px,
  '<=desktop-xl': 1920px,
) !default;

@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "breakpoint #{$breakpoint} not found. Available breakpoints are: #{map-keys($breakpoints)}";
  }
}

@mixin theme($theme) {
  @if (map-get($project-theme, name) == $theme) {
    @content;
  }
}
